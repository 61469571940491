<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: space-between">
      <el-page-header
        @back="back"
        :content="isDetail ? '帖子详情' : postsId ? '编辑帖子' : '发帖'"
      >
      </el-page-header>
      <el-button type="primary" @click="isDetail ? edit() : onSubmit()">{{
        isDetail ? "编辑" : "提交"
      }}</el-button>
    </div>
    <div class="container">
      <el-form ref="form" v-model="posts" label-width="80px">
        <el-form-item label="标题：">
          <div style="display: flex">
            <span v-if="isDetail" style="color: red">{{ posts.title }}</span>
            <el-input
              v-else
              v-model="posts.title"
              style="width: 500px"
              :disabled="isDetail"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="内容：">
          <div style="display: flex">
            <mavon-editor
              :toolbars="toolbars"
              :ishljs="true"
              :codeStyle="codeStyle"
              :externalLink="externalLink"
              @imgAdd="handleEditorImgAdd"
              style="height: calc(100vh - 300px)"
              v-model="value"
              @change="change"
              ref="md"
              :editable="!isDetail"
            />
          </div>
        </el-form-item>
        <el-form-item label="绑定商品">
          <div style="display: flex">
            <el-button @click="showDialog" :disabled="isDetail">{{
              posts.spuName || "去绑定"
            }}</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="选择要绑定的商品" :visible.sync="dialogVisible">
      <el-table
        highlight-current-row
        :data="spuList"
        border
        :max-height="350"
        @current-change="selectData"
        v-loading="dialogLoading"
        style="margin-top: 20px"
      >
        <el-table-column prop="id" label="商品ID"></el-table-column>
        <el-table-column prop="name" label="商品名称"></el-table-column>
        <el-table-column prop="logoUrl" label="商品封面图" align="center">
          <template slot-scope="scope">
            <el-image :src="scope.row.logoUrl" style="width: 120px" fit="contain" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="totalRows"
        :current-page="page"
        :page-size="20"
        style="margin-top: 20px"
        @current-change="getSpuList"
      ></el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="bind">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import PostsApi from "../../request/PostsApi";
import SpuApi from "../../request/SpuApi";
import FileUtil from "../../utils/FileUtil";
import "../../plugins/markdown";
import "highlight.js/styles/monokai-sublime.css"; //因为跟github-markdown.min.css有冲突所以需要提前引入
export default {
  data() {
    return {
      circleId: null,
      postsId: null,
      posts: {
        labelNames: [],
      },
      imageList: [],
      uploading: false,
      dialogVisible: false,
      spuList: [],
      page: 1,
      totalRows: 0,
      dialogLoading: false,
      selectedData: null,
      isDetail: false,
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: true, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true, // 预览
      },
      subfield: true,
      codeStyle: "monokai-sublime",
      externalLink: {
        markdown_css: function () {
          // 这是你的markdown css文件路径
          return "cdn-lib/markdown/github-markdown.min.css";
        },
        hljs_js: function () {
          // 这是你的hljs文件路径
          return "cdn-lib/highlightjs/highlight.min.js";
        },
        hljs_css: function (css) {
          // 这是你的代码高亮配色文件路径
          return "cdn-lib/highlightjs/styles/" + css + ".min.css";
        },
        hljs_lang: function (lang) {
          // 这是你的代码高亮语言解析路径
          return "cdn-lib/highlightjs/languages/" + lang + ".min.js";
        },
        katex_css: function () {
          // 这是你的katex配色方案路径路径
          return "cdn-lib/katex/katex.min.css";
        },
        katex_js: function () {
          // 这是你的katex.js路径
          return "cdn-lib/katex/katex.min.js";
        },
      },
      html: "",
      value: "",
    };
  },
  mounted() {
    this.postsId = this.$route.query.id;
    this.circleId = this.$route.query.circleId;
    this.isDetail = JSON.parse(this.$route.query.isDetail);
    this.posts.circleId = this.circleId;
    if (this.postsId) {
      this.getPostsDetail();
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    edit() {
      this.isDetail = false;
    },
    getPostsDetail() {
      PostsApi.detail({
        circleId: this.circleId,
        id: this.postsId,
      }).then((res) => {
        if (res.success) {
          this.posts = res.data;
          // this.imageList = JSON.parse(this.posts.mediaJson);
          this.value = res.data.content;
        }
      });
    },
    async uploadImage(file) {
      this.uploading = true;
      const result = await FileUtil.uploadFile(
        {
          file: file,
          dir: "posts",
          rename: true,
        },
        this.$http
      );
      this.uploading = false;
      if (result.success) {
        this.imageList.push({ type: "IMAGE", path: result.url });
      }
    },
    handleRemoveImage(index) {
      this.imageList.splice(index, 1);
    },
    change(value, render) {
      //value是markdown语言内容，render是html语言
      this.html = render;
    },
    async handleEditorImgAdd(pos, $file) {
      var formdata = new FormData();
      formdata.append("file", $file);
      const result = await FileUtil.uploadFile(
        {
          file: FileUtil.base64ImgtoFile($file.miniurl),
          dir: "posts",
          rename: true,
        },
        this.$http
      );
      if (result.success) {
        this.$refs.md.$img2Url(pos, result.url); //这里就是引用ref = md 然后调用$img2Url方法即可替换地址
      }
    },
    addImgFitStyle(content) {
      const regex = new RegExp("<img", "gi");
      content = content.replace(regex, `<img style="max-width: 100%; height: auto"`);
      return content;
    },
    showDialog() {
      this.dialogVisible = true;
      this.getSpuList(1);
    },
    getSpuList(page) {
      this.page = page;
      this.dialogLoading = true;
      SpuApi.myList({
        pageNo: this.page,
        pageSize: 20,
        spuType: "COURSE_GOODS",
      })
        .then((res) => {
          this.dialogLoading = false;
          if (res.success) {
            this.spuList = res.data;
          }
        })
        .catch(() => {
          this.dialogLoading = false;
        });
    },
    selectData(val) {
      this.selectedData = val;
    },
    bind() {
      this.dialogVisible = false;
      this.posts.spuId = this.selectedData.id;
    },
    async onSubmit() {
      if (!this.posts.title) {
        this.$message("请填写标题");
        return;
      }
      this.filterImage();
      this.posts.mediaJson = JSON.stringify(this.imageList);
      this.html = this.addImgFitStyle(this.html);
      this.posts.content = this.html;
      this.posts.richText = true;
      this.posts.auditStatus = 'APPROVED';
      const loading = this.$loading({
        lock: true,
        text: "提交中",
        spinner: "el-icon-loading",
      });
      let result = null;
      if (this.postsId) {
        result = await PostsApi.update(this.posts);
      } else {
        result = await PostsApi.insert(this.posts);
      }
      loading.close();
      this.imageList = [];
      if (result.success) {
        this.$message.success(this.postsId ? "修改成功" : "发布成功");
        this.$router.go(-1);
      }
    },
    filterImage() {
      const imgReg = /<img.*?(?:>|\/>)/gi;
      //匹配src属性
      const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
      const arr = this.html.match(imgReg);
      if (!arr) return;
      for (let i = 0; i < arr.length; i++) {
        const src = arr[i].match(srcReg);
        //获取图片地址
        if (src[1]) {
          this.imageList.push({ type: "IMAGE", path: src[1] });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  overflow: auto;
  top: 140px;
  width: calc(100vw - 200px - 40px);
  height: calc(100vh - 140px);
}
.uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  // overflow: hidden;
  width: 150px;
  height: 150px;
}
.uploader:hover {
  border-color: #409eff;
}
.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.uploader-icon:hover {
  color: #409eff;
}
.image-hover {
  position: absolute;
  width: 150px;
  height: 35px;
  bottom: 0;
  .delete {
    display: none;
  }
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.3);
    .delete {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 5px;
      color: white;
      font-size: 24px;
    }
  }
}
</style>
